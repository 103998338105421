import React from "react";

import styles from "./Footer.module.scss";

export const Footer = () => (
  <footer className={styles.footer}>
    <div className="container">
      &copy; Ian Routledge {new Date().getFullYear()}{" "}
      <a href="mailto:hello@ediblecode.com">hello@ediblecode.com</a>
    </div>
  </footer>
);
