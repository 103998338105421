import React from "react";

import styles from "./Hero.module.scss";

type HeroProps = {
  heading: string;
  lead: React.ReactNode;
  children?: React.ReactNode;
};

export const Hero = ({ heading, lead, children }: HeroProps) => (
  <div className={styles.hero}>
    <h1 className={styles.heading}>{heading}</h1>
    <p className={styles.lead}>{lead}</p>
    {children}
  </div>
);
