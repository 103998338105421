import React from "react";
import { FixedObject } from "gatsby-image";
import { Helmet } from "react-helmet";

const defaultDescription =
  "ediblecode / Ian Routledge, Manchester-based lead software engineer";

type SEOProps = {
  title: string;
  description?: string;
  openGraphImage?: FixedObject;
  themeColour?: string;
};

export const SEO = ({
  title,
  themeColour,
  openGraphImage,
  description,
}: SEOProps) => (
  <Helmet
    title={title}
    titleTemplate={`%s | ediblecode`}
    meta={[
      {
        name: `description`,
        content: description || defaultDescription,
      },
      {
        property: `og:description`,
        content: description || defaultDescription,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        property: `og:title`,
        content: `${title} | ediblecode / Ian Routledge`,
      },
      {
        property: `og:image`,
        content: openGraphImage?.src,
      },
      {
        property: `og:image:width`,
        content: "1200",
      },
      {
        property: `og:image:height`,
        content: "630",
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:site`,
        content: "@ediblecode",
      },
      {
        name: `twitter:creator`,
        content: "@ediblecode",
      },
      {
        name: `theme-color`,
        content: themeColour || "#000",
      },
    ]}
  />
);
