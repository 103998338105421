import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import classnames from "classnames";

import styles from "./Header.module.scss";

const canUseDOM: boolean = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export const Header = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [shouldCollapse, updateShouldCollapse] = useState(false);

  useEffect(() => {
    const handler = () => {
      updateShouldCollapse(
        (document.body.scrollTop || document.documentElement.scrollTop) >
          document.documentElement.clientHeight / 4
      );
    };

    window.addEventListener("scroll", handler);

    // clean up
    return () => window.removeEventListener("scroll", handler);
  }, []);

  return (
    <header
      className={classnames(styles.header, {
        [styles.collapsed]: shouldCollapse,
      })}
    >
      <nav className={styles.nav}>
        <Link to="/" className={styles.home}>
          ediblecode <span>/ Ian Routledge</span>
        </Link>
        {canUseDOM && (
          <button
            className={styles.button}
            type="button"
            aria-controls="main-nav"
            aria-expanded={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            Menu&nbsp;
            <svg viewBox="0 0 10 10" aria-hidden="true">
              {isExpanded ? (
                <>
                  <line
                    x1="1"
                    y1="9"
                    x2="9"
                    y2="1"
                    stroke="black"
                    stroke-width="2"
                  />
                  <line
                    x1="1"
                    y1="1"
                    x2="9"
                    y2="9"
                    stroke="black"
                    stroke-width="2"
                  />
                </>
              ) : (
                <path
                  d="M5 0V10"
                  stroke="currentColor"
                  strokeDasharray="2"
                  strokeWidth="10"
                />
              )}
            </svg>
          </button>
        )}
        <ul id="main-nav" className={styles.list}>
          <li>
            <Link
              to="/blog/"
              className={classnames(styles.link, styles.blog)}
              getProps={({ isCurrent, isPartiallyCurrent }) => ({
                [`aria-current`]: isCurrent ? "page" : isPartiallyCurrent,
              })}
            >
              <span>Blog</span>
            </Link>
          </li>
          <li>
            <Link
              to="/photos/"
              className={classnames(styles.link, styles.photos)}
              getProps={({ isCurrent, isPartiallyCurrent }) => ({
                [`aria-current`]: isCurrent ? "page" : isPartiallyCurrent,
              })}
            >
              <span>Photos</span>
            </Link>
          </li>
          <li>
            <Link
              to="/about/"
              className={classnames(styles.link, styles.about)}
            >
              <span>About</span>
            </Link>
          </li>
          <li>
            <Link
              to="/connect/"
              className={classnames(styles.link, styles.connect)}
            >
              <span>Connect</span>
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};
